import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/challenge-3/",
  "date": "2020-05-22T00:00:00.000Z",
  "title": "Images, you b*tch",
  "blurb": "My challenges and victories whilst attempting challenge #3",
  "tags": ["image", "markdown", "jsx"],
  "featuredImg": "../../images/batu-gezer-1HcNgs3RrKE-unsplash.jpg",
  "imageAlt": "Pink PSP"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hello world, again,`}</p>
    <p>{`This week the challenge was all about adding images to pages and showcasing the brilliantly, gorgeous gatsby-image API. To summarise the objectives for those unfamiliar, the tasks this week were to:`}</p>
    <ul>
      <li parentName="ul">{`add an image to markdown-based blog post`}</li>
      <li parentName="ul">{`create an about page`}</li>
      <li parentName="ul">{`learn about gatsby-image api to transform selfie into a black and white square`}</li>
      <li parentName="ul">{`implement above on the about page`}</li>
      <li parentName="ul">{`do lighthouse audit before and after adding image (extra)`}</li>
    </ul>
    <p>{`More details can be seen at the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/blog/100days/gatsby-image/"
      }}>{`gatsby 100 day challenge`}</a>{`.`}</p>
    <p>{`But boy, oh boy, was adding an image more difficult than I thought it would be. I'm sure the `}<span className="code">{`<`}{`img`}{`>`}</span>{` could've sufficed but no... To fully utilise Gatsby's image optimization through the gatsby-image api one has to work for it.`}</p>
    <p>{`And work for it I did.`}</p>
    <h2>{`Adding an image to Markdown`}</h2>
    <p>{`Firstly, a quick read through of Gatsby's `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/working-with-images-in-markdown/"
      }}>{`working with images in markdown post`}</a>{` provided an outline of steps to incorprate images in my blog:`}</p>
    <ol>
      <li parentName="ol">{`Add the images to `}<span className="code">{`src/images`}</span>{` folder`}</li>
      <li parentName="ol">{`Add the folder to `}<span className="code">{`gatsby-config.js`}</span></li>
      <li parentName="ol">{`Add image's relative url/path to frontmatter`}</li>
    </ol>
    <p>{`Following said steps, only frustratingly resulted in an error message:`}</p>
    <p className="code-error">Field "image" must not have a selection since type "String" has no subfields</p>
    <p><img parentName="p" {...{
        "src": "https://steamuserimages-a.akamaihd.net/ugc/823506058661954611/B3685679465B62E28B582A6A31781532F65DD46F/",
        "alt": "Jake Peralta"
      }}></img></p>
    <p>{`What does this even mean? After some light googling came the sensation of derp when I discovered that someone else had made the same error: the url/source dir was incorrect. After that was realised, another error appeared. Cool.`}</p>
    <p>{`Turns out blog posts with no images were unaccounted for and thus errored out. There was an error in my logic--fair call! I edited `}<span className="code">{`blog-post.js`}</span>{` to ignore the function of image adding should a url not be present in its frontmatter... and lo-and-behold 'twas fixed and I accomplished my first real task!`}</p>
    <p><em parentName="p">{`*`}{`Pats self on back`}{`*`}</em></p>
    <h2>{`Adding an image to JSX`}</h2>
    <p>{`Reading about `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/gatsby-image/"
      }}>{`the API`}</a>{` proved harder than I had planned. This may be due to my irregular sleep cycle or the fact I haven't left the house in a few days (thanks pandemic) but I felt less focused as of late, and lack of focus is not conducive to productivity, let alone reading documentation. But I digress.`}</p>
    <p>{`So, to add an image to my JSX about page I read up on `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/working-with-images/"
      }}>{`another Gatsby doc`}</a>{`. To summarise, the steps I made to achieve this were:`}</p>
    <ol>
      <li parentName="ol">{`Script up the GraphQL query`}</li>
      <li parentName="ol">{`Add `}<span className="code">{`(`}{`{`}{` data `}{`}`}{`)`}</span>{` to the function to utilise the data returned from query`}</li>
      <li parentName="ol">{`Add `}<span className="code">{`import Img from 'gatsby-image'`}</span></li>
      <li parentName="ol">{`Add `}<span className="code">{`<`}{`Img`}{`>`}</span>{` to JSX`}</li>
    </ol>
    <p>{`It was pretty straight forward. And bam! – 3 of the tasks completed.`}</p>
    <p>{`✅ ✅ ✅`}</p>
    <h2>{`Lighthouse this`}</h2>
    <p>{`Finally, the lighthouse audit.`}</p>
    <p>{`Before:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2de679094082f634f21fac7c4484079b/df438/before-image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAABTUlEQVQY022QzW7bMBCE9RauU1P8kSiJf5ITN6llu7WbBIFT9JCgRVD0kLz/U3ylVOTWwyw4i+HuzBZaKuS1RzxeobzFZH6dFOetoLUKozXjILm/EVRGUUrNfi05bQSiVDRNw+FwoOs6pJQUUisubhPLlxuWnz1erfh1/MCfbwuu/ApvV7zeLfh9WuCsoO8UL8clP79e5GWCEBNvb69sNhuEEBS1qUj7T8Tve8I6YUzNw5fE031P8I4UIz9Okcdjou08tpI83wXGyzo7lPR9z263YxgGdE5T1HXNkPoZwQcmnvo1cbikaVt8iLjQz05cjvVxJXIvUduWshRst1vO5zPjOKKUopiKKMsMkQXl3Jyse6PxztPUkuhsvpFDdlV20tPmRdO9lNKzvqqqnMz8GzjZ/B+CtfPArjHE0OK8R3eWmE/gnJs/v2un9zv/Cwposiul2L6VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Before embedded image",
            "title": "Before embedded image",
            "src": "/static/2de679094082f634f21fac7c4484079b/5a190/before-image.png",
            "srcSet": ["/static/2de679094082f634f21fac7c4484079b/772e8/before-image.png 200w", "/static/2de679094082f634f21fac7c4484079b/e17e5/before-image.png 400w", "/static/2de679094082f634f21fac7c4484079b/5a190/before-image.png 800w", "/static/2de679094082f634f21fac7c4484079b/c1b63/before-image.png 1200w", "/static/2de679094082f634f21fac7c4484079b/df438/before-image.png 1556w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`After:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e493ee342d4c4e64545344570e768ee4/df438/after-image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPklEQVQY022RzU7DMBCE+xZQimMHO4ljx02gQVHLT/k7VKhcQAIkDn3/p/jYBAlx4DC7mvVqNDue5dqgLzzqqcOEgomvarLtElNabG64TJrHS4U9Myids24125Uik92iKNhsNnjv0VozMzI8uU8cfwwshhotAvPnc+avPfO2onUnfD0c83l3RF0o2lrzvp3zdrvAnSlCbDgcDvR9j1KKmcst6aqn2V8TL5ZM/GGg3d/gY6CJkZf7hr3A14HCat6eAtcrh8o0KaXJYdd15HnOzDpHm5YTYog44d3I2w5fVdShERfy1iwJvmZxOrpKuKKaHA3DwG63Y71eY4yRk6WoLBNIJtJ/uMLL6V4ESmeI3lJVklFlJ0dlWU55jbujqzFHa+2P4Dj4D7FwBHHs5WNSrIiSVe7dJBhC+BUb+1/xb0PCsbLkrJU0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "After embedded image",
            "title": "After embedded image",
            "src": "/static/e493ee342d4c4e64545344570e768ee4/5a190/after-image.png",
            "srcSet": ["/static/e493ee342d4c4e64545344570e768ee4/772e8/after-image.png 200w", "/static/e493ee342d4c4e64545344570e768ee4/e17e5/after-image.png 400w", "/static/e493ee342d4c4e64545344570e768ee4/5a190/after-image.png 800w", "/static/e493ee342d4c4e64545344570e768ee4/c1b63/after-image.png 1200w", "/static/e493ee342d4c4e64545344570e768ee4/df438/after-image.png 1556w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Pretty darn good I must say. Image optimisation at its finest! There was only a three percent drop in performance but that's not too bad!`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`I'd say this has been the most eventful week of my Gatsby challenge. I still need to clean up my code and have still yet to implement a reuseable component for the top nav bar (added to my todos). However, the week has been successful in terms of meeting the objectives and my productivity.`}</p>
    <p>{`Featuring images on your blog can initially be a pain in the ass, but just like riding a bike once you know you know–`}<em parentName="p">{`until something changes in the code base`}</em>{`. Also, it does add a bit of flare to your site and makes it less boring, I suppose.`}</p>
    <h2>{`Resources`}</h2>
    <p>{`Main docs:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/working-with-images/"
      }}>{`https://www.gatsbyjs.org/docs/working-with-images/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/working-with-images-in-markdown/"
      }}>{`https://www.gatsbyjs.org/docs/working-with-images-in-markdown/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/images-and-files/"
      }}>{`https://www.gatsbyjs.org/docs/images-and-files/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/gatsby-image/"
      }}>{`https://www.gatsbyjs.org/docs/gatsby-image/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-image/"
      }}>{`https://www.gatsbyjs.org/packages/gatsby-image/`}</a></p>
    <p>{`Supporting docs:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/plugins/"
      }}>{`https://www.gatsbyjs.org/docs/plugins/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-source-filesystem/"
      }}>{`https://www.gatsbyjs.org/packages/gatsby-source-filesystem/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/gatsby-config/"
      }}>{`https://www.gatsbyjs.org/docs/gatsby-config/`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/gatsby-link/"
      }}>{`https://www.gatsbyjs.org/docs/gatsby-link/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      